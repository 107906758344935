export const environment = {
  production: false,

  hash: '1W2XXHz8Vc',

  uris: {
    self: 'https://samen.ggzond.fun',
    selfprod: 'https://samen.ggzond.fun',

    assets: 'https://studio.gamebus.eu/assets/static',

    // api: 'http://localhost:8024/v2',
    api: 'https://api-new.gamebus.eu/v2',

    // airbridge: 'http://localhost:3000',
    airbridge: 'https://airbridge-api.gamebus.eu',
  },

  client: {
    id: 'gamebus_studio_app',
    secret: 'JkxcLv5MSlqLEr7h0hn4OhYd6lZgEw7h',
    dataprovider: 'GameBus Studio',
  },

  languages: ['en', 'nl'], // Default language first

  // Default consent items
  consent: [
    { tk: 'name-visible', required: true },
    { tk: 'terms-conditions', required: true, link: 'https://blog.gamebus.eu/?page_id=1066' }
  ],

};
